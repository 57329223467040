@import 'inc/colors';
@import 'inc/gemma/gemma-settings';

.tabs-menu {
  height: 51px;
  border-bottom: 1px solid $gemma-border-light;
  li {
    height: 51px;
    line-height: 51px;
    float: left;
    margin-right: 10px;
    background-color: #f7f7f7;
    border-top: 1px solid $gemma-border-light;
    border-right: 1px solid $gemma-border-light;
    border-left: 1px solid $gemma-border-light;
    list-style: none;
    border-radius: 4px 4px 0 0;
    @include rem('padding', 12px);
    cursor: pointer;
    font-family: 'Source Sans Pro', "Helvetica Neue", Arial, sans-serif;
    text-rendering: optimizeLegibility;
    h4 {
      margin: 0;
    }

    a {
      padding: 10px;
      color: #000;
      text-decoration: none;
      outline: none;
    }

    &.current {
      position: relative;
      background-color: #fff;
      border-bottom: 1px solid #fff;
      z-index: 5;
      a {
        color: $color-primary-blue;
      }
    }

    &.error {
      border-color: $color-primary-orange;
      a {
        color: $color-primary-orange;
      }
    }
  }
}

.tab {
  background-color: #fff;
  margin-bottom: 20px;
  width: auto;
}

.tab-content {
  display: none;
}

